.Dropzone {
    width: 100%;
    height: 100%;
    position: fixed;
}

.Dropzone.active {
    background-color: grey;
    opacity: 0.2;
    margin: 0;
}
