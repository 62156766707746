.Thing {
    border-radius: 6px;
    box-shadow: 5px 5px 2px -4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    transition: transform 0.035s ease-out, width 0.1s ease-out;
    position: fixed;
}

.Thing.selected {
    box-shadow: 10px 10px 20px -10px rgba(184, 184, 184, 0.9);
    transition: transform 0.035s ease-out, box-shadow 0.1s ease-in-out;
}

.Thing.hidden-card {
    box-shadow: none;
    background-color: transparent;
}

.Thing:hover {
    cursor: move;
}
