.ToolbarButton {
    background-color: #31253f !important;
    border-radius: 50%;
    color: #ffeaee !important;
}

.ToolbarButton:hover {
    background-color: #31253f !important;
    opacity: 0.7;
}
