.DreamBoard {
    width: 210mm !important;
    height: 297mm !important;
    border-radius: 10px;
    background-color: #ffffff;
    background-image: radial-gradient(circle, #adadad 1px, transparent 1px);
    background-size: 25px 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
    margin-bottom: -500px;
    box-sizing: border-box;
    position: relative;
    transform-origin: top;
}

.printable {
    height: 100%;
}
