@keyframes gradient-animation {
    0% {
      background: linear-gradient(45deg, #130B1E, #FFC0ED, #50FF50, #FFD550);
      background-size: 400% 400%;
    }
    25% {
      background: linear-gradient(45deg, #FFD550, #130B1E, #FFC0ED, #50FF50);
      background-size: 400% 400%;
    }
    50% {
      background: linear-gradient(45deg, #50FF50, #FFD550, #130B1E, #FFC0ED);
      background-size: 400% 400%;
    }
    75% {
      background: linear-gradient(45deg, #FFC0ED, #50FF50, #FFD550, #130B1E);
      background-size: 400% 400%;
    }
    100% {
      background: linear-gradient(45deg, #130B1E, #FFC0ED, #50FF50, #FFD550);
      background-size: 400% 400%;
    }
  }
  
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #130B1E, #FFC0ED, #50FF50, #FFD550);
    background-size: 400% 400%;
    animation: gradient-animation 15s ease infinite;
  }
  