.StickyThing {
    transition: transform 0.05s ease-out;
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    padding-left: 50px;
    margin-top: 10px;
    font-family: "Walter Turncoat, cursive";
}

.StickyThing:hover {
    cursor: pointer;
}

.StickyThing:focus {
    outline: none;
}

.selected {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
