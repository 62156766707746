body {
    background: #130b1e !important;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
    height: 100%;
    width: 100%;
    margin: 0;
}

@media print {
    body * {
        visibility: hidden;
    }

    .printable {
        margin-top: -120px;
        transform: scale(1.4) !important;
        transform-origin: top !important;
    }

    .printable,
    .printable * {
        visibility: visible;
    }
}
